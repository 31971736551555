import React from 'react';

const Input = ({ label, id, name, required, white, onBlur, onChange, options, defaultValue }) => {
  return (
    <div>
      <label className={`mb-2 block  text-sm font-medium  ${white ? 'text-white' : 'text-black'}`} htmlFor={id}>
        {label}
        {required && '*'}
      </label>

      <select
        id={id}
        name={name}
        required={required}
        onBlur={onBlur}
        onChange={onChange}
        defaultValue={defaultValue}
        className={`focus:outline-none w-full appearance-none rounded-xl border p-4 leading-tight text-gray-700 shadow  ${white ? 'focus:ring-white' : 'focus:ring-brand'}`}
      >
        {options.map((entry, i) => {
          return (
            <option value={entry} key={i}>
              {entry}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default Input;
