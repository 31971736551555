import { graphql } from 'gatsby';
import parse from 'html-react-parser';
import React from 'react';
import Button from '~components/common/Button';
import Container from '~components/common/Container';
import FaqItem from '~components/common/FaqItem';
import PricingGrid from '~components/common/PricingGrid';
import Layout from '~components/layouts/Default';
import Krankenkassencheck from '~components/quiz/Krankenkassencheck';
import PageHeader from '~components/sections/PageHeader';
import H1 from '~components/typography/H1';
import P from '~components/typography/P';

export default function Page({ data, location }) {
  return (
    <Layout location={location} title="Krankenkassen Erstattungscheck" desc="Wie hoch ist die Krankenkassen Förderung deiner Krankenkasse? Mache hier den Erstattungscheck!" active="Krankenkassencheck" green>
      <PageHeader title="Erstattung deiner Krankenkasse" image={data.headerImage} imageMobile={data.headerImageMobile} classNameImage="rounded-xl">
        <P className="mt-3 mb-5 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
          Absolviere unsere Entspannungs- oder Stressmanagementkurse und erhalte einen Gesundheitsbonus deiner gesetzlichen Krankenkasse. Du möchtest wissen, wie hoch die Erstattung ausfällt? Dann mach hier den Erstattungscheck.
        </P>
        <Button anchor="/kurse/krankenkasse/#check" text="Erstattungscheck" />
      </PageHeader>
      <Krankenkassencheck />
      <Container>
        <div id="kurse">
          <PricingGrid />
        </div>
      </Container>
      <div className="overflow-hidden pb-16">
        <Container className="relative " dotsRight dotsLeft>
          <div className="mb-8 text-center">
            <H1>FAQ</H1>
            <P className="mb-5">
              <a href={`mailto:${data.site.siteMetadata.contact.mail}`}>Falls deine Frage nicht dabei ist, schreibe gerne an - hallo@entspannungshelden.de</a>
            </P>
          </div>
          <dl className="mx-auto max-w-2xl space-y-4">
            {data.allFaq.nodes.map((faq) => {
              return <FaqItem title={faq.frage} text={parse(faq.text)} key={faq.id} />;
            })}
          </dl>
          <div className="mt-8 text-center">
            <Button text="Noch fragen?" to="/kontakt" />
          </div>
        </Container>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    allFaq {
      nodes {
        text
        frage
        id
      }
    }
    site {
      siteMetadata {
        contact {
          mail
        }
      }
    }

    headerImage: file(relativePath: { eq: "landingpages/krankenkasse/header.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    headerImageMobile: file(relativePath: { eq: "landingpages/krankenkasse/header_mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
