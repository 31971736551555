import React, { useState } from 'react';
import Container from '~components/common/Container';
import Feature from '~components/common/Feature';
import GridTwo from '~components/common/GridTwo';
import Select from '~components/forms/elements/Select';
import H2 from '~components/typography/H2';
import H3 from '~components/typography/H3';
import P from '~components/typography/P';

const zuschuss = [
  { krankenkasse: 'AOK Baden-Württemberg', foerderung: '80 €' },
  { krankenkasse: 'AOK Bayern', foerderung: '75 €' },
  { krankenkasse: 'AOK Bremen/Bremerhaven', foerderung: '75 €' },
  { krankenkasse: 'AOK Hessen', foerderung: 'Bis zu 150 €' },
  { krankenkasse: 'AOK Niedersachen', foerderung: '75 €' },
  { krankenkasse: 'AOK Nordost', foerderung: '85 €' },
  { krankenkasse: 'AOK Nordwest', foerderung: 'Bis zu 250 €' },
  { krankenkasse: 'AOK Plus', foerderung: 'Bis zu 150 €' },
  { krankenkasse: 'AOK Rheinland/Hamburg', foerderung: '75 €' },
  { krankenkasse: 'AOK Rheinland-Pflaz/Saarland', foerderung: '75 €' },
  { krankenkasse: 'AOK Sachen-Anhalt', foerderung: '80 €' },
  { krankenkasse: 'DAK-Gesundheit', foerderung: '75 €' },
  { krankenkasse: 'Handelskrankenkasse', foerderung: '100 €' },
  { krankenkasse: 'HEK - Hanseatische Krankenkasse', foerderung: 'Bis zu 250 €' },
  { krankenkasse: 'Kaufmännische Krankenkasse - KKH', foerderung: 'Online 150 €, Live 134 €' },
  { krankenkasse: 'Techniker Krankenkasse', foerderung: 'Online 100 €, Live 75 €' },
  { krankenkasse: 'Bundesinnungskrankenkasse Gesundheit', foerderung: 'Online 100 €, Live 75 €' },
  { krankenkasse: 'IKK Classic', foerderung: '90 €' },
  { krankenkasse: 'IKK gesund plus', foerderung: '90 €' },
  { krankenkasse: 'IKK Südwest', foerderung: '75 €' },
  { krankenkasse: 'IKK - Die innovationskasse', foerderung: '130 €' },
  { krankenkasse: 'Innungskrankenkasse Brandenburg und Berlin', foerderung: '75 €' },
  { krankenkasse: 'Audi BKK', foerderung: '90 €' },
  { krankenkasse: 'Bahn-BKK', foerderung: 'Bis zu 150 €' },
  { krankenkasse: 'Bertelsmann BKK', foerderung: '80 €' },
  { krankenkasse: 'Betriebskrankenkasse BPW Bergisch Achsen KG', foerderung: '80 €' },
  { krankenkasse: 'Betriebskrankenkasse der BMW AG', foerderung: '100 €' },
  { krankenkasse: 'Betriebskrankenkasse der G.M. Pfaff AG Kaiserslautern', foerderung: 'Bis zu 150 €' },
  { krankenkasse: 'Betriebskrankenkasse EVM', foerderung: '125 €' },
  { krankenkasse: 'Betriebskrankenkasse Firmus', foerderung: '103 €' },
  { krankenkasse: 'Betriebskrankenkasse Groz-Beckert', foerderung: '80 €' },
  { krankenkasse: 'Betriebskrankenkasse Mahle', foerderung: '75 €' },
  { krankenkasse: 'Betriebskrankenkasse Miele', foerderung: '125 €' },
  { krankenkasse: 'Betriebskrankenkasse Mobil', foerderung: '100 €' },
  { krankenkasse: 'Betriebskrankenkasse Schwarzwald-Baar-Heuberg', foerderung: '125 €' },
  { krankenkasse: 'Betriebskrankenkasse Vereinigte Deutsche Nickel-Werke', foerderung: 'Bis zu 160 €' },
  { krankenkasse: 'Betriebskrankenkasse WMF Württembergische Metallwarenfabrik AG', foerderung: 'Bis zu 200 €' },
  { krankenkasse: 'BKK24', foerderung: '125 €' },
  { krankenkasse: 'BKK B. Braun Aesculap', foerderung: '80 €' },
  { krankenkasse: 'BKK Akzo Nobel Bayern', foerderung: '134 € bis zu 150 €' },
  { krankenkasse: 'BKK Deutsche Bank AG', foerderung: '75 €' },
  { krankenkasse: 'BKK Diakonie', foerderung: '100 €' },
  { krankenkasse: 'BKK Dürkopp Adler', foerderung: '80 €' },
  { krankenkasse: 'BKK Euregio', foerderung: 'Bis zu 150 €' },
  { krankenkasse: 'BKK EWE', foerderung: 'Bis zu 150 €' },
  { krankenkasse: 'BKK exklusiv', foerderung: 'Bis zu 200 €' },
  { krankenkasse: 'BKK Faber-Castell & Partner', foerderung: 'Bis zu 150 €' },
  { krankenkasse: 'BKK Freudenberg', foerderung: '100 €' },
  { krankenkasse: 'BKK Gildemeister Seidensticker', foerderung: '75 €' },
  { krankenkasse: 'BKK Herkules', foerderung: '100 €' },
  { krankenkasse: 'BKK Linde', foerderung: '100 €' },
  { krankenkasse: 'BKK Melitta HMR', foerderung: '120 €' },
  { krankenkasse: 'BKK MTU', foerderung: '120 €' },
  { krankenkasse: 'BKK Pfalz', foerderung: '134 € bis max 150 €' },
  { krankenkasse: 'BKK Provita', foerderung: '110 €' },
  { krankenkasse: 'BKK Public', foerderung: 'Bis zu 155 €' },
  { krankenkasse: 'BKK PwC', foerderung: 'Bis zu 150 €' },
  { krankenkasse: 'BKK Rieker Ricosta Weisser', foerderung: '100 €' },
  { krankenkasse: 'BKK Salzgitter', foerderung: '75 €' },
  { krankenkasse: 'BKK Scheufelen', foerderung: '150 €' },
  { krankenkasse: 'BKK Stadt Augsburg', foerderung: '98,5 €' },
  { krankenkasse: 'BKK Technoform', foerderung: '75 €' },
  { krankenkasse: 'BKK VBU', foerderung: '134 € bis max 150 €' },
  { krankenkasse: 'BKK Verbundplus', foerderung: '134 € bis max 150 €' },
  { krankenkasse: 'BKK Voralb Heller Index Leuze', foerderung: '77,5 €' },
  { krankenkasse: 'BKK Werra-Meissner', foerderung: '75 €' },
  { krankenkasse: 'BKK Wirtschaft & Finanzen', foerderung: '150 €' },
  { krankenkasse: 'BKK Würth', foerderung: '80 €' },
  { krankenkasse: 'BKK ZF & Partner', foerderung: '80 €' },
  { krankenkasse: 'Bosch BKK', foerderung: '80 €' },
  { krankenkasse: 'Continentale Betriebskrankenkasse', foerderung: '125 €' },
  { krankenkasse: 'Daimler Betriebskrankenkasse', foerderung: '134 € bis max 150 €' },
  { krankenkasse: 'Debeka BKK', foerderung: '119 € bis max 150 €' },
  { krankenkasse: 'Die Bergische Krankenkasse', foerderung: 'Bis zu 280 €' },
  { krankenkasse: 'Energie-Betriebskrankenkasse', foerderung: '80 €' },
  { krankenkasse: 'Ernst & Young BKK', foerderung: '100 €' },
  { krankenkasse: 'Gemeinsame Betriebskrankenkasse der Gesellschaften der Textilgruppe Hof', foerderung: '90 €' },
  { krankenkasse: 'Heimat Krankenkasse', foerderung: '80 €' },
  { krankenkasse: 'Karl Mayer Betriebskrankenkasse', foerderung: '80 €' },
  { krankenkasse: 'Koenig & Bauer BKK', foerderung: '100 €' },
  { krankenkasse: 'Krones Betriebskrankenkasse', foerderung: '90 €' },
  { krankenkasse: 'Merck BKK', foerderung: '100 €' },
  { krankenkasse: 'Mhplus Betriebskrankenkasse', foerderung: '70 €' },
  { krankenkasse: 'Novitas BKK', foerderung: '134 € bis max 150 €' },
  { krankenkasse: 'Pronova BKK', foerderung: '134 € bis max 150 €' },
  { krankenkasse: 'R+V Betriebskrankenkasse', foerderung: '80 €' },
  { krankenkasse: 'Salus BKK', foerderung: '100 €' },
  { krankenkasse: 'Securvita BKK', foerderung: '100 €' },
  { krankenkasse: 'Siemens-Betriebskrankenkasse', foerderung: '80 €' },
  { krankenkasse: 'SKD BKK', foerderung: '125 €' },
  { krankenkasse: 'Südzucker BKK', foerderung: '130 €' },
  { krankenkasse: 'TUI BKK', foerderung: '155 €' },
  { krankenkasse: 'Viactiv BKK', foerderung: 'Bis zu 275 €' },
  { krankenkasse: 'Vivida BKK', foerderung: 'Bis zu 150 €' },
  { krankenkasse: 'Knappschaft', foerderung: '80 €' },
  { krankenkasse: 'Landwirtschaftliche Krankenkasse', foerderung: 'Min 119 €' }
];

zuschuss.sort((a, b) => (a.krankenkasse > b.krankenkasse ? 1 : b.krankenkasse > a.krankenkasse ? -1 : 0));

const Krankenkassencheck = () => {
  const [krankenkasse, setKrankenkasse] = useState(undefined);
  const [erstattung, setErstattung] = useState(undefined);

  const handleKrankenkasse = (e) => {
    zuschuss.every((entry) => {
      if (entry.krankenkasse === e.target.value) {
        setKrankenkasse(entry.krankenkasse);
        setErstattung(entry.foerderung);
        return false;
      }

      setKrankenkasse(undefined);
      return true;
    });
  };

  return (
    <>
      <Container>
        <GridTwo itemsCenter>
          <div id="check">
            <div className="space-y-4">
              <H2>Erstattungscheck</H2>
              <H3>
                <span className="text-lg font-medium">Wie hoch ist deine Erstattung?</span>
              </H3>
              <P>Dieser Kurs ist als Präventionsmaßnahme zertifiziert und wird von Krankenkassen bezuschusst. Prüfe hier, wie viel dir deine Krankenkasse erstattet.</P>
              <Select label="Deine Krankenkasse" id="krankenkasse" onBlur={(e) => handleKrankenkasse(e)} onChange={(e) => handleKrankenkasse(e)} options={['Wähle deine Krankenkasse'].concat(zuschuss.map((entry) => entry.krankenkasse))} />
              {typeof krankenkasse !== 'undefined' && (
                <div className="bg-gray-50 py-6">
                  <H3 className="mb-3">Großartig!</H3>
                  <P className="mb-6">Für Versicherte der {krankenkasse} wird unser Angebot bezuschußt.</P>
                  <div className="text-center">
                    <div className="inline-block rounded-full bg-brand-green px-4 py-2 text-lg text-brand-gray">Erstattung: {erstattung}*</div>
                    <div className="text-xs text-gray-500">
                      {krankenkasse}
                      <br />
                      *Angaben ohne Gewähr
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div>
            <div className="rounded-xl bg-white p-6">
              <H2 className="mb-8">So funktioniert die Erstattung</H2>
              <div className="space-y-4">
                <Feature
                  svg={
                    <div className="flex h-6 w-6 items-center justify-center rounded-full bg-brand-green text-brand-gray" style={{ minWidth: '1.5rem' }}>
                      1
                    </div>
                  }
                  title="Kaufe über diese Seite deinen passenden Kurs."
                />
                <Feature
                  svg={
                    <div className="flex h-6 w-6 items-center justify-center rounded-full bg-brand-green text-brand-gray" style={{ minWidth: '1.5rem' }}>
                      2
                    </div>
                  }
                  title="Schließe den Kurs ab."
                />
                <Feature
                  svg={
                    <div className="flex h-6 w-6 items-center justify-center rounded-full bg-brand-green text-brand-gray" style={{ minWidth: '1.5rem' }}>
                      3
                    </div>
                  }
                  title="Nach Abschluss erhältst du deine Teilnahmebescheinigung innerhalb von 24 Stunden per Email zugesendet."
                />
                <Feature
                  svg={
                    <div className="flex h-6 w-6 items-center justify-center rounded-full bg-brand-green text-brand-gray" style={{ minWidth: '1.5rem' }}>
                      4
                    </div>
                  }
                  title="Reiche deine Teilnahmebescheinigung und die Rechnung bei deiner Krankenkasse ein."
                />
              </div>
            </div>
          </div>
        </GridTwo>
      </Container>
    </>
  );
};

export default Krankenkassencheck;
