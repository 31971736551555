import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import GridTwo from './GridTwo';
import PricingCard from './PricingCard';

const PricingGrid = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allKurse {
          nodes {
            price
            title
            link
            frequenz
            desc
            id
            landingpage
            features {
              value
              field {
                label
              }
            }
          }
        }
      }
    `
  );

  return (
    <GridTwo itemsStart>
      {data.allKurse.nodes.map((item) => {
        return <PricingCard title={item.title} desc={item.desc} price={item.price} priceFrequenz={item.frequenz} features={item.features} link={item.link} landingpage={item.landingpage} key={item.id} />;
      })}
    </GridTwo>
  );
};

export default PricingGrid;
